module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1KE46RYZEQ"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lavilotte-Rolle Frédéric","short_name":"Lavilotte-Rolle","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"./src/images/lavilotte-rolle_icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"3039f191ffea4d4dfd1d86ec9b5aba51"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-transformer-photo/gatsby-browser.js'),
      options: {"plugins":[],"endpoint":"https://photo.lavilotte-rolle.fr/api/graphql","token":"482c1664-410b-43a2-b47b-e4ae3a29bbe4","domain":"https://photo.lavilotte-rolle.fr"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
